<!--manage-->
<template>
  <div class="panel webManage">
    <div class="title">Hot Product Manage</div>
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form">
        <el-form-item label="Contract No:" size="">
          <template>
            <el-input
              v-model.trim="form.contractNo"
              placeholder="Pleas enter the Contract No"
              size=""
              disabled
            />
          </template>
        </el-form-item>

        <el-form-item label="SKU ID:" size="">
          <template>
            <el-input
              v-model.trim="form.skuId"
              placeholder="Pleas enter the SKUID"
              size=""
            />
          </template>
        </el-form-item>
        <el-form-item label="SKU Name:" size="">
          <template>
            <el-input
              v-model.trim="form.skuName"
              placeholder="Pleas enter the SKUName"
              size=""
            />
          </template>
        </el-form-item>
        <el-button type="" @click="offBtn">Reset</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchBtn">
          Search
        </el-button>
      </el-form>
      <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin-top: 32px"
        @click="addBtn"
      >
        Add
      </el-button>
    </div>
    <div class="panel-body">
      <el-table
        :data="tableList"
        fit
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column
          label="SKU id"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.skuId || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="SKU Name"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.skuName || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="SKU image">
          <template slot-scope="scope">
            <img
              :src="scope.row.imgUrl.indexOf('http') == -1 ? imgHeaderInit + scope.row.imgUrl : scope.row.imgUrl"
              alt=""
              width="128px"
              style="cursor: pointer; margin-left: 16px"
              @click="handlePreview(scope.row.imgUrl.indexOf('http') == -1 ? imgHeaderInit + scope.row.imgUrl : scope.row.imgUrl)"
            >
          </template>
        </el-table-column>
        <el-table-column label="Creat Time" width="180">
          <template slot-scope="scope">
            {{ formatDate(scope.row.created) || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="Operate" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button
              
              type="text"
              size="small"
              @click="handleDel(scope.row)"
            >
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      width="680px"
    >
      <el-form
        ref="postForm"
        class="dialog-form"
        :model="postForm"
        :rules="rules"
        label-width="80px"
        :inline="true"
      >
        <el-form-item label="SKU ID" prop="skuId">
          <el-input
            v-model.trim="postForm.skuId"
            type
            placeholder="Please enter the SKU ID"
            oninput="value=value.replace(/[^\d\,]/g,'')"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button
          v-if="dialogStatus == 'create'"
          type="primary"
          @click="createData('postForm')"
        >
          Confirm
        </el-button>
        <el-button
          v-else-if="dialogStatus == 'update'"
          
          type="primary"
          @click="updateData('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
    <!-- 缩略图 -->
    <el-dialog :visible.sync="dialogVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageUrl"
        alt=""
      >
    </el-dialog>
  </div>
</template>

<script>
// 引入API接口
import { bygetSKU, fuBygetSKU, addHotJinShopping } from '@/api/user/list';
import { checkLongThree } from '@/api/user/rules.js';
import {image_base_url} from '@/common/config/index.js'
const defaultForm = {
  contractNo: '',
  skuId: '',
  skuIds: []
};
export default {
  // 引入局部刷新
  inject: ['reload'],
  data() {
    return {
      emptyImgSrc: '',
      imgHeaderInit: image_base_url,
      postForm: Object.assign({}, defaultForm),
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 1
      },
      outeObject: this.$route.query,
      // 配置弹窗信息
      textMap: {
        update: 'Update',
        create: 'Create'
      },
      dialogFormVisible: false,
      dialogVisible: false,
      dialogImageUrl: '',
      dialogStatus: 'update',
      // 搜索条件
      form: {
        contractNo: this.$route.query.contractNo,
        skuId: '',
        skuName: ''
      },
      // 后端获取表格信息
      tableList: [],
      // 非空验证
      rules: {
        skuId: [
          {
            required: true,
            message: 'Please enter the Squence number',
            trigger: 'blur'
          },
          { validator: checkLongThree, length: 301, trigger: 'blur' }
        ]
      },
    };
  },
  watch: {
    dialogFormVisible: function() {
      this.$nextTick(()=>{
        this.$refs['postForm'].resetFields();
      })
    }
  },
  created() {
    // 初始化表数据
    this.getFirstData();
    this.emptyImgSrc = '';
  },
  methods: {
    formatDate(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? '0' + MM : MM;
      let d = date.getDate();
      d = d < 10 ? '0' + d : d;
      let h = date.getHours();
      h = h < 10 ? '0' + h : h;
      let m = date.getMinutes();
      m = m < 10 ? '0' + m : m;
      let s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      return MM + '-' + d + '-' + y + ' ' + h + ':' + m + ':' + s;
    },
    // 获取页面数据
    getFirstData() {
      bygetSKU(
        this.outeObject.contractNo,
        Object.assign(
          {
            pageNo: this.listQuery.pageCode,
            pageSize: this.listQuery.pageSize
          },
          this.form
        )
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          // 暂无数据
          this.tableList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn() {
      this.postForm = Object.assign({}, defaultForm);
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
    },
    // 列表-删除按钮
    handleDel(row) {
      if(this.tableList.length <= 1){
        return this.$message({
          message:'Please go to the list page to delete all the best-selling products of this merchant'
        })
      }
      this.$confirm('Are you sure to remove this banner?', 'warning', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      })
        .then(() => {
          let obj = {
            ids: [row.id]
          }
          fuBygetSKU(obj).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully delete',
                type: 'success'
              });
              this.getFirstData();
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
              
            }
          });
        })
        .catch(function() {});
    },
    // 弹窗-新增确定
    createData() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          this.postForm.contractNo = this.outeObject.contractNo;
          this.postForm.skuIds = this.postForm.skuId.split(',')
          let obj = {
            skuIds: this.postForm.skuIds,
            contractNo: this.postForm.contractNo
          }
          addHotJinShopping(obj).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully added',
                type: 'success'
              });
              this.getFirstData();
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
              
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 弹窗-修改确定
    updateData() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    handlePreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    }
  }
};
</script>
<style lang="less" scoped>
.webManage {
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
  ::v-deep .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
}
.heard_box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  div {
    label {
      margin-right: 5px;
    }
  }
}
.serch_box {
  width: 100%;
  background: #fff;
  padding: 24px 16px 18px;
}
.title {
  height: 66px;
  border-bottom: 1px solid rgba(183, 188, 201, 0.3);
  padding: 24px 16px;
  font-size: 16px;
  font-weight: Bold;
  line-height: 17px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #2262ff;
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.fixed_form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div {
    display: flex;
    //flex-direction: column;
    margin-bottom: 0;
    /deep/.el-form-item__label {
      text-align: left;
    }
  }
}
.dialog-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div {
    display: flex;
    //flex-direction: column;
    margin-bottom: 0;
    /deep/.el-form-item__label {
      text-align: left;
    }
  }
}
.panel-body {
  padding: 0 16px;
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}
</style>
